<template>
  <main class="Welcome">
    <TheSubHeader
      title="Boas-vindas! :)"
      page-title-tag="Welcome"
      organizer-gap="1rem"
      organizer-columns="3fr 1fr 1fr"
    >
      <template v-if="!isSeller()" #afterButtons>
        <BaseButton
          icon="faq"
          color="primary-base"
          label="Precisa de ajuda?"
          track="Welcome - Clicked on Help Button in Subheader"
          class="TheSubHeader__button"
          @click="goToServiceDesk"
        />
      </template>
    </TheSubHeader>

    <div class="Welcome__container">
      <BaseCarousel
        class="Welcome__carousel"
        :list="images"
        arrows
      />
    </div>
  </main>
</template>

<script>
import { TheSubHeader } from '@/components/organisms'
import { BaseButton, BaseCarousel } from '@/components/atoms'

export default {
  name: 'Welcome',
  components: {
    TheSubHeader,
    BaseButton,
    BaseCarousel
  },
  data(){
    return {
      images: [
        '/welcome/banners/01.png'
      ]
    }
  },
  computed:{
  },
  mounted () {
    const type = JSON.parse(localStorage.getItem('user'))?.roles?.[0]?.name
    if (type === 'motorista_dropoff') {
      this.$router.push({
        path: '/packages/incoming'
      })
    }
  },
  methods: {
    goToServiceDesk() {
      window.open('https://jira-olist.atlassian.net/servicedesk/customer/portal/35/create/1459', '_blank')
    },

    getUserType() {
      if (JSON.parse(localStorage.getItem('user'))) {
        let user = JSON.parse(localStorage.getItem('user'))
        if (user.roles?.[0]) {
          return user.roles?.[0].name
        } else {
          return false
        }
      } else {
        return false
      }
    },

    isSeller() {
      return this.getUserType() === 'lojista'
    }
  }
}

</script>

<style lang="scss" scoped>
.Welcome {
  &__container {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
    height: calc(100% - 110px);
    @media (max-width: $viewport-md) {
      max-width: 1000px;
    }
    @media #{$mobile-view} {
      width: 90%;
    }
  }
  &__carousel {
    height: calc(100vh - 230px);
  }
}
</style>